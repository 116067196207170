import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import Helmet from 'react-helmet'
import TrustBox from 'components/trustpilot'

import { logo, over_ons_1, bnr, sprout, emerce, salarisnet } from 'images/over-ons'

const ReviewPage = ({location}) => (
  <Layout location={location}>
    <main className="over-ons">

      <Helmet>
        <title>Klantervaringen met Employes | Eenvoud & gemak</title>
        <meta name="description" content="Bekijk de reviews van Employes salarisadministratie. Klanten zeggen: eenvoudig op te zetten en goede service." />
        <meta itemprop="name" content="Klantervaringen met Employes | Eenvoud & gemak" />
        <meta itemprop="description" content="Bekijk de reviews van Employes salarisadministratie. Klanten zeggen: eenvoudig op te zetten en goede service."/>
        <meta itemprop="image" content="/static/meta-img.png" />

        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>

      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <img src={logo} alt="Employes logo large" className="margin-s-bottom" />
              <p className="streamer large wide center">Waarom bedrijven Employes aanraden.</p>
            </div>
          </div>
        </div>
      </header>

      <section className="mission padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <p className="eyebrow">Reviews</p>
              <h2>Lees de reviews van tevreden Employes-klanten</h2>
              <p className="margin-m-bottom">De salarisadministratie is een enorm belangrijke aspect voor ieder bedrijf met personeel en moet altijd voor 100% in orde zijn. Je wilt een partij waar je van op aan kunt. Daarom is naast het gebruiksgemak van onze software een snelle klantenservice een belangrijke peiler.</p>
              <p>Om te kijken hoe goed we het doen, vragen we onze gebruikers om na de eerste verloning een review te schrijven op het onafhankelijke platform van Trustpilot.  Iedere review wordt - ongeacht of deze positief of negatief is - direct op Trustpilot geplaatst.</p>
            </div>
          </div>
        </div>
      </section>


      <div className="margin-m-bottom">
        <TrustBox></TrustBox>
      </div>



      <section className="important padding-xl">
        <div className="container-md">
          <div className="grid center text-center">
            <div className="col-12">
              <p className="eyebrow">Samengevat</p>
              <h2 className="margin-xl-bottom">Wat maakt Employes zo goed?</h2>
            </div>
          </div>

          <div className="grid yg">
            <div className="col-4">
              <h5>Eenvoud</h5>
              <p>De software is eenvoudig op te zetten en voor iedereen te begrijpen. Met een paar muisklikken doe je loonaangifte, en genereer je loonstrookjes.</p>
            </div>

            <div className="col-4">
              <h5>Gemak</h5>
              <p>Met Employes niet meer de afhankelijkheid (en kosten) van een boekhouder. Je kunt direct online alles regelen.</p>
            </div>

            <div className="col-4">
              <h5>Support</h5>
              <p>De ondersteuning bij vragen is snel en goed. Er is inhoudelijke kennis aanwezig om je te helpen bij lastigere vragen.</p>
            </div>
          </div>
        </div>
      </section>

    </main>
  </Layout>
)

export default ReviewPage
